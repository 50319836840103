import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap'
import { Navbar, NavDropdown, Form, FormControl, Button, Nav } from 'react-bootstrap'
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import Home from './Home';
import Contact from './Contact';
import About from './About';
import Language from './Language';
import brealogo from "./images/brealogo.png"
import brealogo2 from "./images/brealogo2.png"

function NavbarComp() {
  return (
    <Router>
        <div>
            <Navbar className="navbar fw-bold" expand="lg">
            <Container>
                <Navbar.Brand href="/" className="brand">
                <img
                    src={brealogo2}
                    width="200"
                    height="200"
                    className="d-inline-block"
                    alt="logo"
                />
                {/* BreaHealth */}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/language">
                        <Nav.Link>Language</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about">
                        <Nav.Link>About US</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/contact">
                        <Nav.Link>Contact US</Nav.Link>
                    </LinkContainer>

                    {/* <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About US</Nav.Link>
                    <Nav.Link as={Link} to="/contact">Contact US</Nav.Link> */}
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>

    </Router>
  );
}

export default NavbarComp;