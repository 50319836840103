import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from './components/Contact';
import About from './components/About';
import Language from './components/Language';
import Question1 from "./components/Question1";
import Question2 from "./components/Question2";
import Question3 from "./components/Question3";
import Question4 from "./components/Question4";
import Question5 from "./components/Question5";
import Result from './components/Result';
import Test from './components/Test';

const Routers = () => {
  return (
    <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path='/about' element={<About/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/language' element={<Language/>} />

                <Route path="/question1" element={<Question1/>}/>
                <Route path="/question2" element={<Question2/>}/>
                <Route path="/question3" element={<Question3/>}/>
                <Route path="/question4" element={<Question4/>}/>
                <Route path="/question5" element={<Question5/>}/>
                <Route path="/result" element={<Result/>}/>
                <Route path="/test" element={<Test/>}/>
            </Routes>
        </BrowserRouter>
  )
}

export default Routers