import './App.css';
import NavbarComp from './components/NavbarComp'
import 'bootstrap/dist/css/bootstrap.min.css';
import Routers from './Routers';

function App() {
  return (
    <div className="App">
      <NavbarComp />

      <Routers />
      
    </div>
  );
}

export default App;
