import { React, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import CheckBox from './forms/CheckBox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson, faPersonDress, faTransgender } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { changeGender } from '../store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Question5() {
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const gender = useSelector((state) => {
    return state.form.gender;
  });

  const handleGenderChange = (event) => {
    dispatch(changeGender(event.target.value));
  };

  return (
    <>
    <style type="text/css">
        {`.btn-flat {
                    background-color: purple; 
                    color: white;}
          .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
        `}
    </style>

    <header class="queshead">
      <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <h1 class="mx-auto my-0 text-uppercase">What sex are you?</h1>
              <h3 class="text-black mx-auto mt-2 mb-5">This will help us understand more about who can best help you. </h3>
              

              <Container>
                <section className="section">
                    
                  <div className="columns">
                    <div className="column is-4">
                      <Row>
                            <Col>      
                                <div className="card border-0">
                                    <div className="card-image">
                                        <figure className="image is-1by1">
                                        <FontAwesomeIcon size="6x" icon={faPerson} />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="media-content">
                                        <input class="form-check-input" type="checkbox" value="male" onChange={handleGenderChange} id="flexCheckDefault"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <p className="title is-4">Male</p>
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            </Col>  
                            <Col>
                                <div className="card border-0">
                                <div className="card-image">
                                    <figure className="image is-1by1">
                                    <FontAwesomeIcon size="6x" icon={faPersonDress} />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media-content">
                                    <input class="form-check-input" type="checkbox" value="female" onChange={handleGenderChange} id="flexCheckDefault"/>
                                    <label class="form-check-label" for="flexCheckDefault">
                                        <p className="title is-4">Female</p>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="card border-0">
                                <div className="card-image">
                                    <figure className="image is-1by1">
                                    <FontAwesomeIcon size="6x" icon={faTransgender} />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media-content">
                                    <input class="form-check-input" type="checkbox" value="female" onChange={handleGenderChange} id="flexCheckDefault"/>
                                    <label class="form-check-label" for="flexCheckDefault">
                                        <p className="title is-4">Transgender</p>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>


                    </div>
                  </div>
                </section>
              </Container>

             <div class="d-grid gap-2 col-6 mx-auto my-3">
                <Button class = "continue-button my-3" variant="flat" size="xxl" onClick={() => navigate('/result')}>Continue</Button>
              </div>
          </div>
        </div>
      </div>
    </header>

    </>
  )
}

export default Question5