import { React, useState } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom"
import CheckBox from './forms/CheckBox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { changeAge } from '../store';

function Question3() {
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const age = useSelector((state) => {
    return state.form.age;
  });

  const handleAgeChange = (event) => {
    dispatch(changeAge(event.target.value));
  };

  return (
    <>
    <style type="text/css">
        {`.btn-flat {
                    background-color: purple; 
                    color: white;}
          .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
        `}
    </style>

    <header class="queshead">
      <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <h1 class="mx-auto my-0 text-uppercase">What brought you to Brea Health today?</h1>
              
              <div className="container">
                <section className="section">
                  <div className="columns">
                    <div className="column is-4">

                    <div className="card border-0">
                      <div className="card-image">
                        <figure className="image is-1by1">
                          <FontAwesomeIcon size="6x" icon={faSquarePlus} />
                        </figure>
                      </div>
                      <div className="card-content">

                        <Form>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="form" placeholder="Enter your symptom, condition, or past diagnoses..." />
                          </Form.Group>
                        </Form>

                      </div>
                    </div>

                    </div>
                  </div>
                </section>
              </div>

              <div class="d-grid gap-2 col-6 mx-auto my-3">
                <Button class="continue-button" variant="flat" size="xxl" onClick={() => navigate('/question4')}>Continue</Button>
              </div>
          </div>
        </div>
      </div>
    </header>

    </>
  )
}

export default Question3