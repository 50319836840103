import { React, useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import doctor from "./images/doctor.png"

function Result() {
  return (

    <>
    <style type="text/css">
        {`.btn-flat {
                    background-color: purple; 
                    color: white;}
          .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
        `}
    </style>
    
    <header class="resuhead">
      <div class="container px-4 px-lg-5 d-flex h-200 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <h1 class="mx-auto my-0 text-uppercase">We found the best doctor for you!</h1>
                
              <div class="container">
                  <div class="row">
                      <div class="col-md-6  my-5">
                      <img
                        src={doctor}
                        width="300"
                        height="350"
                        className="d-inline-block"
                        alt="logo"
                      />
                      </div>

                      <div class="col-md-6 text-start">
                        <h2 class="ms-auto my-5">Jane Doe, MD</h2>
                        <h2 class="ms-auto my-3">☑　Experienced with Asian Patients</h2>
                        <h2 class="ms-auto my-3">☑　Expert at low risk obstetrics</h2>
                        <h2 class="ms-auto my-3">☑　Meets your time constraints</h2>
                        <h2 class="ms-auto my-3">☑　Has a good track record with past patients</h2>
                        <h2 class="ms-auto my-3">☑　Your insurance is covered too</h2>
                      </div>
                  </div>
              </div>

              <div class="d-grid gap-2 col-6 mx-auto">
                <Button variant="flat" size="xxl" >Book an Appointment</Button>
              </div>
          </div>
        </div>
      </div>
    </header>
    
    </>
  )
}

export default Result