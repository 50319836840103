import { React, useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
import CheckBox from './forms/CheckBox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faStethoscope } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { changeGender } from '../store';

function Question1() {
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const gender = useSelector((state) => {
    return state.form.gender;
  });

  const handleGenderChange = (event) => {
    dispatch(changeGender(event.target.value));
  };

  return (
    <>
    <style type="text/css">
        {`.btn-flat {
                    background-color: purple; 
                    color: white;}
          .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
        `}
    </style>

    <header class="queshead">
      <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <h1 class="mx-auto my-0 text-uppercase">What are you looking for today?</h1>
              <h3 class="text-black mx-auto mt-2 mb-5">What kind of doctor do you need to see?</h3>
              
              <div className="container">
                <div class="row">
                    <div className="card">
                      <div className="card-image">
                        <figure className="image is-1by1">
                          <FontAwesomeIcon size="6x" icon={faUserDoctor} />
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="media-content">
                          <input class="form-check-input" type="checkbox" value="male" onChange={handleGenderChange} id="flexCheckDefault"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            <p className="title is-4">Specialty Doctor</p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-image">
                        <figure className="image is-1by1">
                          <FontAwesomeIcon size="6x" icon={faStethoscope} />
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="media-content">
                          <input class="form-check-input" type="checkbox" value="female" onChange={handleGenderChange} id="flexCheckDefault"/>
                          <label class="form-check-label" for="flexCheckDefault">
                            <p className="title is-4">Primary Care</p>
                          </label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div class="d-grid gap-2 col-6 mx-auto">
                <Button variant="flat" size="xxl" onClick={() => navigate('/question2')}>Continue</Button>
              </div>
          </div>
        </div>
      </div>
    </header>

    </>
  )
}

export default Question1