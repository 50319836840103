import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"

const Home = () => {
  const navigate = useNavigate()
  return (
    <>
    <style type="text/css">
        {`.btn-flat {
                    background-color: purple; 
                    color: white;}
          .btn-xxl {
                    padding: 1rem 1.5rem;
                    font-size: 1.5rem;
                    }
        `}
    </style>

    <header class="masthead">
      <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <h1 class="mx-auto my-0 text-uppercase">Search for Your Best Doctors</h1>
              <h3 class="text-black mx-auto mt-2 mb-5">Personalized care, just a few clicks away</h3>
              <div class="d-grid gap-2 col-6 mx-auto">
                <Button variant="flat" size="xxl" onClick={() => navigate('/question1')}>Find your doctor now</Button>
              </div>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default Home