import { createSlice, nanoid } from '@reduxjs/toolkit';

const formSlice = createSlice({
    name: 'form',
    initialState: {
      id: nanoid(),
      gender: '',
      race:'',
      age: 0,
    },
    reducers: {
      changeGender(state, action) {
        state.gender = action.payload;
      },
      changeRace(state, action) {
        state.race = action.payload;
      },
      changeAge(state, action) {
        state.age = action.payload;
      }
    },
  });
  
  export const { changeGender, changeRace, changeAge } = formSlice.actions;
  export const formReducer = formSlice.reducer;
  